"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SlidesDatabase;
(function (SlidesDatabase) {
    /**Get All Home Slider Data */
    function getSliders() {
        let sliders = [];
        sliders = require("./slides.json");
        return sliders;
    }
    SlidesDatabase.getSliders = getSliders;
})(SlidesDatabase || (SlidesDatabase = {}));
exports.default = SlidesDatabase;
