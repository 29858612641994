"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
class UserAccount {
    static UserLogin(data) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const post = yield fetch("https://api.candrastore.com/login.php", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                });
                const result = yield post.json();
                if (result.status) {
                    localStorage.setItem("userID", data.userID);
                    return true;
                }
                else
                    return false;
            }
            catch (err) {
                return false;
            }
        });
    }
    static isLogin() {
        return __awaiter(this, void 0, void 0, function* () {
            const userID = localStorage.getItem("userID");
            if (!userID)
                return false;
            try {
                const check = yield fetch(`https://api.candrastore.com/check.php?id=${userID}`);
                const result = yield check.json();
                if (result.status)
                    return true;
                else
                    return false;
            }
            catch (err) {
                return false;
            }
        });
    }
    static Logout() {
        localStorage.removeItem("userID");
    }
    static getAccount(id) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const check = yield fetch(`https://api.candrastore.com/check.php?id=${id}`);
                const result = yield check.json();
                if (result.status)
                    return new UserAccount(result.data);
                else
                    return null;
            }
            catch (err) {
                return null;
            }
        });
    }
    constructor(data) {
        this.data = data;
    }
    getPicture() {
        return this.data.picture;
    }
    getID() {
        return this.data.userID;
    }
    getEmail() {
        return this.data.email;
    }
    getName() {
        return this.data.name;
    }
    getProvider() {
        return this.data.provider;
    }
}
export default UserAccount;
