"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
var GamesDatabase;
(function (GamesDatabase) {
    /**Get all games data */
    function getGames() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                try {
                    const result = yield fetch("https://api.candrastore.com/game.php").then((res) => res.json());
                    resolve(result);
                }
                catch (err) {
                    reject(err);
                }
            }));
        });
    }
    GamesDatabase.getGames = getGames;
    /**Get all game types */
    function getGameTypes() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                let data = [];
                const games = yield getGames();
                games.forEach((game) => {
                    if (!data.includes(game.type))
                        data.push(game.type);
                });
                resolve(data);
            }));
        });
    }
    GamesDatabase.getGameTypes = getGameTypes;
    /**Get all games data by type */
    function getGamesType(type) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                let data = [];
                const games = yield getGames();
                games.forEach((game) => {
                    if (game.type.toLowerCase() === type.toLowerCase()) {
                        data.push(game);
                    }
                });
                resolve(data);
            }));
        });
    }
    GamesDatabase.getGamesType = getGamesType;
    /**Get games by search */
    function searchGames(search) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                let data = [];
                const games = yield getGames();
                games.forEach((game) => {
                    if (game.name.toLowerCase().includes(search.toLowerCase())) {
                        data.push(game);
                    }
                });
                return data;
            }));
        });
    }
    GamesDatabase.searchGames = searchGames;
})(GamesDatabase || (GamesDatabase = {}));
export default GamesDatabase;
