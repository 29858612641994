"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PaymentDatabase;
(function (PaymentDatabase) {
    /**Get all payments */
    function getPayments() {
        let payments = [];
        payments = require("./payments.json");
        return payments;
    }
    PaymentDatabase.getPayments = getPayments;
    /**Get all payment types */
    function getPaymentTypes() {
        let paymentTypes = [];
        getPayments().forEach((payment) => {
            if (!paymentTypes.includes(payment.type))
                paymentTypes.push(payment.type);
        });
        return paymentTypes;
    }
    PaymentDatabase.getPaymentTypes = getPaymentTypes;
    /**Get all payments data by type */
    function getGamesType(type) {
        let paymentsFilter = [];
        getPayments().forEach((payment) => {
            if (payment.type.toLowerCase() === type.toLowerCase()) {
                paymentsFilter.push(payment);
            }
        });
        return paymentsFilter;
    }
    PaymentDatabase.getGamesType = getGamesType;
    /**Check payment support price */
    function isSupport(payment, price) {
        if (price < 0)
            return false;
        if (!payment.priceSupport)
            return true;
        if (payment.priceSupport.max && payment.priceSupport.max < price)
            return false;
        if (payment.priceSupport.min && payment.priceSupport.min > price)
            return false;
        return true;
    }
    PaymentDatabase.isSupport = isSupport;
})(PaymentDatabase || (PaymentDatabase = {}));
exports.default = PaymentDatabase;
